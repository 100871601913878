<template>
  <path
    d="M17.7106 2.08984C17.0806 2.08984 16.4606 2.20984 15.8806 2.44984C15.3006 2.68984 14.7706 3.03984 14.3306 3.48984L10.6006 7.21984C10.8806 7.17984 11.1706 7.13984 11.4606 7.13984C12.2506 7.13984 13.0406 7.28984 13.7706 7.58984L16.0906 5.26984C16.5206 4.86984 17.1006 4.64984 17.6906 4.65984C18.2806 4.66984 18.8406 4.90984 19.2606 5.32984C19.6806 5.74984 19.9206 6.30984 19.9306 6.89984C19.9406 7.48984 19.7206 8.05984 19.3206 8.49984L15.1406 12.6798C14.2806 13.5398 12.7706 13.5398 11.9106 12.6798L10.1406 14.4498C10.5806 14.8998 11.1106 15.2498 11.6906 15.4898C12.2706 15.7298 12.8906 15.8498 13.5206 15.8498C14.1506 15.8498 14.7706 15.7298 15.3506 15.4898C15.9306 15.2498 16.4606 14.8998 16.9006 14.4498L21.0806 10.2698C21.5306 9.82984 21.8806 9.29984 22.1206 8.71984C22.3606 8.13984 22.4806 7.51984 22.4806 6.88984C22.4806 5.61984 21.9706 4.39984 21.0806 3.50984C20.1806 2.60984 18.9706 2.10984 17.7006 2.10984L17.7106 2.08984Z"
    fill="currentColor"
  />
  <path
    d="M7.19 22.5025C5.81 22.5025 4.5 21.9625 3.53 20.9825C2.54 20.0025 2 18.6925 2 17.3125C2 16.6325 2.13 15.9625 2.39 15.3325C2.65 14.7025 3.03 14.1325 3.52 13.6525L7.7 9.4625C9.65 7.5125 13.08 7.5125 15.03 9.4625L15.31 9.7425L12.98 12.0825L12.7 11.8025C11.99 11.0925 10.74 11.0925 10.03 11.8025L5.85 15.9825C5.53 16.3325 5.35 16.8025 5.36 17.2925C5.36 17.7825 5.57 18.2425 5.91 18.5925C6.26 18.9425 6.72 19.1325 7.21 19.1425C7.7 19.1825 8.17 18.9725 8.53 18.6425L11.01 16.1625L11.26 16.2625C12.15 16.6325 13.13 16.7725 14.07 16.6425L15.37 16.4725L10.86 20.9825C10.38 21.4625 9.81 21.8425 9.18 22.1125C8.55 22.3725 7.88 22.5025 7.2 22.5025H7.19ZM11.37 8.7925C10.23 8.7925 9.09 9.2025 8.27 10.0325L4.09 14.2125C3.68 14.6225 3.36 15.1025 3.14 15.6325C2.92 16.1625 2.81 16.7325 2.81 17.3125C2.81 18.4825 3.27 19.5825 4.1 20.4125C4.93 21.2425 6.03 21.7025 7.2 21.7025C7.78 21.7025 8.34 21.5925 8.87 21.3725C9.4 21.1525 9.88 20.8325 10.29 20.4225L13.21 17.5025C12.52 17.4825 11.85 17.3425 11.2 17.1025L9.09 19.2125C8.57 19.6925 7.87 19.9325 7.2 19.9325C6.5 19.9225 5.85 19.6425 5.35 19.1425C4.86 18.6525 4.58 17.9925 4.56 17.2925C4.55 16.5925 4.8 15.9225 5.27 15.4125L9.46 11.2325C10.4 10.3025 11.91 10.2125 12.95 10.9725L14.16 9.7625C13.37 9.1125 12.36 8.7925 11.36 8.7925H11.37Z"
    fill="currentColor"
  />
  <path
    d="M6.54974 6.3298C6.61974 6.3698 6.69974 6.3898 6.77974 6.3898C6.92974 6.3898 7.06974 6.3098 7.14974 6.1798C7.27974 5.9698 7.20974 5.6998 6.99974 5.5798L5.40974 4.6198C5.19974 4.4898 4.92974 4.5598 4.80974 4.7698C4.67974 4.9798 4.74974 5.2498 4.95974 5.3698L6.54974 6.3298Z"
    fill="currentColor"
  />
  <path
    d="M10.8508 4.78005C11.0308 4.78005 11.2008 4.67005 11.2608 4.49005L11.8808 2.74005C11.9608 2.51005 11.8408 2.26005 11.6108 2.18005C11.3808 2.10005 11.1308 2.22005 11.0508 2.45005L10.4408 4.20005C10.3608 4.43005 10.4808 4.68005 10.7108 4.76005C10.7608 4.78005 10.8108 4.78005 10.8608 4.78005H10.8508Z"
    fill="currentColor"
  />
  <path
    d="M8.46996 4.62986C8.51996 4.62986 8.57996 4.62986 8.62996 4.59986C8.84996 4.50986 8.96996 4.25986 8.87996 4.02986L8.18996 2.26986C8.09996 2.04986 7.84996 1.92986 7.61996 2.01986C7.39996 2.10986 7.27996 2.35986 7.36996 2.58986L8.05996 4.34986C8.12996 4.51986 8.28996 4.62986 8.46996 4.62986Z"
    fill="currentColor"
  />
  <path
    d="M17.3699 16.1602C17.2899 16.1602 17.2099 16.1702 17.1399 16.2102C17.0099 16.2802 16.9099 16.4102 16.8999 16.5702C16.8799 16.8102 17.0699 17.0202 17.3099 17.0402L19.1599 17.1702C19.3999 17.1902 19.6099 17.0002 19.6299 16.7602C19.6499 16.5202 19.4599 16.3102 19.2199 16.2902L17.3699 16.1602Z"
    fill="currentColor"
  />
  <path
    d="M14.2509 19.5102C14.0909 19.5902 13.9909 19.7702 14.0209 19.9602L14.2709 21.8002C14.3009 22.0402 14.5209 22.2102 14.7609 22.1702C15.0009 22.1402 15.1709 21.9202 15.1309 21.6802L14.8809 19.8402C14.8509 19.6002 14.6309 19.4302 14.3909 19.4702C14.3409 19.4702 14.2909 19.4902 14.2509 19.5102Z"
    fill="currentColor"
  />
  <path
    d="M16.4299 18.55C16.4299 18.55 16.3399 18.61 16.2999 18.65C16.1399 18.83 16.1599 19.11 16.3399 19.27L17.7599 20.52C17.9399 20.68 18.2199 20.66 18.3799 20.48C18.5399 20.3 18.5199 20.02 18.3399 19.86L16.9199 18.61C16.7799 18.49 16.5899 18.47 16.4299 18.55Z"
    fill="currentColor"
  />
</template>
